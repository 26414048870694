.wrapper {
  width: 100%;
  // @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  padding-left: 265px;
  will-change: auto;
  transition: padding .15s;
  // @include transition(padding .15s);
}

.wrapper-unfoldable {
  width: 100%;
  // @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  padding-left: 0;
  will-change: auto;
  transition: padding .15s;
  // @include transition(padding .15s);
}

@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 720px) and (max-height: 720px) {
  .wrapper {
    padding-left: 160px;
  }
}


@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .wrapper {
    padding-left: 25px;
  }
}