@import "src/scss/__variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,300;1,400;1,500&display=swap');
// If you want to override variables do it here
// @import "./variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";
@import "@coreui/coreui-pro/scss/themes/dark";

@import 'react-redux-toastr/src/styles/index';

@import "./layout";

// If you want to add custom CSS you can put it here.
@import "./custom";

/* Webkit Scrollbar (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  /* Firefox Scrollbar */
  html {
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
  }
  