
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
	box-sizing: border-box !important;
}

html,
body {
	overflow-x: hidden !important;
	width: 100% !important;
	margin: 0 !important;
	padding: 0 !important;
	font-size: 14px;
	background-color: #F4F4F4;
	color:#4c444d;

}


.container,
.row,
.col,
.app-card {
	max-width: 100vw !important;
	overflow-x: hidden !important;
	box-shadow: none !important;
	border-radius: 16px !important;
}
.card-container{border-radius: 0;}
.card,.app-card{max-width: 100vw !important;	box-shadow: none !important; overflow: inherit !important;}

.row,
.col {
	overflow: unset !important;
}

body {
	font-family: 'Roboto', sans-serif;
}

.brand-logo {
	max-width: 130px !important;
	height: auto !important;
	max-height: 32px;
}


.full-width {
	width: 100%;
}

.margin-top-10 {
	margin-top: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.top-nav-dropdown {
	width: 300px;
	margin-right: 0px;
}

.email-campaign-pie-chart {
	height: 250px;
	padding: 20px;
}

.text-center {
	text-align: center;
}

.tox-statusbar__right-container {
	opacity: 0;
}

.form-check-input:checked {
	background-color: #DD3731 !important;
	border-color: #DD3731 !important;
}

.form-check.success .form-check-input:checked {
	background-color: #DD3731 !important;
	border-color: #DD3731 !important;
}

.step.hidden {
	display: none;
}

.d-flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-table {
	font-size: 14px;
}

.form-floating>.form-control {
	height: calc(3rem + calc(var(--cui-border-width) * 2));
	min-height: calc(3rem + calc(var(--cui-border-width) * 2));
	line-height: 1.1;
	padding: 0.7rem 0.5rem;
}

.form-floating>.form-label {
	padding: 0.7rem 0.5rem;
}

.logo-container {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}

.logo-container img {
	width: 60%;
}

.header-text {
	text-align: center;
}

.text-decoration-none {
	text-decoration: none;
}

.round-profile-img {
	border-radius: 50%;
	border: 1px solid #d9dbdf;
	padding: 10px;
	width: 250px;
}

.card.profile-form {
	background: transparent;
	border: none;
	box-shadow: none;
}

.profile-img-container {
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.profile-img-container img {
	width: 150px;
}

.overflow-text {
	display: inline-block;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.template-preview-img {
	height: auto !important;
	object-fit: contain;
	min-height: 150px;
}

.training-preview-img {
	height: auto !important;
	border-radius: 0;
	min-height: 150px;
}

.training-preview-poster {
	position: relative;
	cursor: pointer;
}

.training-play-icon {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	color: white;
}

.template-preview-poster {
	position: relative;
	cursor: pointer;
}

.template-preview-icon {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	color: black;
}

.min-w-300 {
	min-width: 300px;
}

.template-dropdown-actions .dropdown-toggle::after {
	display: none;
}

#import_group_employee_modal button.btn.btn-transparent {
	// visibility: hidden;
}

.input-group .form-multi-select {
	width: 170px !important;
	padding-right: 30px !important;
}

.input-group .form-multi-select-dropdown {
	width: 200px;
}

::-ms-input-placeholder {
	color: #999999 !important;
	font-size: 14px;
}

::placeholder {
	color: #999999 !important;
	font-size: 14px;
}

.form-multi-select.form-multi-select-with-cleaner {
	margin-right: 8px !important;
}

.card-text span.mb-1.text-description {
	display: block;
	max-height: 80px;
	text-overflow: ellipsis;
	overflow: auto; margin-bottom: 15px !important;
}

.login-footer a {
	text-decoration: none;
	color: $gray-900;
}

.d-flex.login-footer * {
	margin-right: 10px;
}

.forgot-password-text {
	text-align: left;
}

.forgot-password-text a {
	color: #c33e58 !important;
	text-decoration: none;
	cursor: pointer;
}

.card.p-6 {
	padding: 50px 45px;
	border-radius: 20px;
}

.text-decoration-none {
	cursor: pointer;
}

.login-container {
	min-height: 600px;
}

svg.app_icon {
	height: 50px;
	width: 50px;
}

.widget_success {
	background-color: #0d650d !important;
}

.widget_info {
	background-color: rgb(24 88 151) !important;
}

.widget_warning {
	background-color: rgb(236 170 26) !important;
}

.widget_danger {
	background-color: rgb(241 65 65) !important;
}

.form-floating>label {
	top: -12px !important;
	font-size: 13px !important;
}

.form-floating>input::placeholder {
	font-size: 12px !important;
}

.pd-5 {
	padding: 5px;
}

.sidenav-wrapper {
	margin-top: 8px !important;
}

.nav-items {
	margin: 0 12px;
}


.centered-item {
	// display: flex;
	// align-items: center;
	// justify-content: flex-start;
}

.pagination .page-item.active {
	width: 32px !important;
}

.active {
	font-weight: 600;
	border-radius: 20px !important;
	color: #000 !important;
}

.active-indicator {
	color: #000 !important;
}

.nav-group-parent {
	padding: 4px 15px;
	height: 32px !important;
	cursor: pointer;
}

.sidebar-nav {
	max-width: 256px !important;
}

.sidebar-nav-item {
	color: #4C444D !important;
	width: 100%;
}


.header-nav {
	margin-right: -9px;
}

.sidebar-nav .nav-link {
	width: 100%;
	display: block;
	padding: 0px 16px; height: 32px; line-height: 32px;

}
.nav-group-parent:hover,
.sidebar-nav .nav-link:hover{background-color: rgba(221, 55, 49, 0.1); border-radius: 20px !important;}
.sidebar-nav .nav-link.active {
	height: 32px !important;
}

.centered-item .nav-item {
	display: block; width: 100%;
}

.nav-group.show {
	background: rgba(221, 55, 49, 0.1) !important;
	border-radius: 20px !important;
}

.sidebar-nav-item .nav-icon,
.sidebar-nav-item .nav-name,
.sidebar-nav-item .nav-badge {
	color: inherit !important;
}

.active-nav-item .nav-icon,
.active-nav-item .nav-name,
.active-nav-item .nav-badge {
	color: #000 !important;
}

.centered-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.align-menu-items {
	display: block;
	color: #4C444D;
	font-size: 14px !important;
	letter-spacing: 0.1px !important;
	padding: 0px 16px 0;
	margin-left: 0 !important;
	font-weight: 500;
}

.sidebar-nav svg.nav-icon {
	margin-right: 13px;
	width: 16px;
}
.sidebar-nav-group .nav-group-parent{ padding-left: 32px; }
.sidebar-nav-group .sidebar-nav-item .sidebar-nav-group .nav-link{ padding-left: 40px; }
.sidebar-nav-group .nav-group-parent svg.nav-icon,
.sidebar-nav-group .sidebar-nav-item .sidebar-nav-group .nav-link svg.nav-icon{display: none;}
.header-sidebar-wrapper {
	display: flex;
	width: 100%;
}

.app-sidebar {
	width: 250px;
	flex-shrink: 0;
	height: auto;
}

.app-header {
	flex-grow: 1;
	height: auto;
}

.sidebar-container {
	width: 256px !important;
	margin-top: 64px !important;
	box-shadow: none !important;
}

.wrapper {
	padding-left: 240px;
}

.sidebar-brand {
	box-shadow: none !important;
	border-radius: 100px 0 0 100px;
	width: 94%;
	margin-left: 18px !important;
	justify-content: flex-start;
	padding-left: 14px !important;
	display: none !important;
}

.header-container {
	background: #fff;
	box-shadow: none !important;
	border-radius: 0 100px 100px 0;
	margin-top: 8px;
	width: calc(100% - 30px);
	position: fixed !important;
	padding: 0 !important;
	min-height: 48px !important;
	left: 16px; border-radius: 40px;

}


.custom-card {
	max-width: 80%;
	margin: 0 auto;
	background: #fff !important;
}

.login-button.btn-primary {
	background: #DD3731 !important;
	border-radius: 30px;width: 100% !important; height:40px;
}
.login-button:hover,
.login-secondary-btn.btn-primary {
	background: #fae0df !important;
	border-radius: 30px;
	box-shadow: none !important;
	color: #000;width: 100% !important; 
	height:40px;
}

.login-secondary-btn:hover{ background-color: #DD3731 !important; color: #fff !important;}

.btn-divider {
	border: 1px solid #DD3731;
	
}

.input-border {
	border-radius: 30px !important;
	background: #EBEDEF;
	border: none !important;
}

.input-group-custom {
	position: relative;
}

.input-group-custom .icon-container {
	position: absolute;
	left: 8px;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	z-index: 1;
}


.input-group-custom .input-border {
	padding-left: 52px;
	padding-right: 40px;
}

.input-group-custom .icon-container-left {
	background: #fff !important;
	border-radius: 100px !important;
	position: absolute;
	left: 8px;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	z-index: 20 !important;
}

.input-group-custom .icon-container-right {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	z-index: 20 !important;
}

.app-card {
	border-radius: 16px;
	padding: 16px, 8px, 8px, 8px;
	background: #fff !important;
	color: #1E1A1F;
	margin-top: 00px;
	margin-bottom: 20px;
}

.header-title {
	font-weight: 500;
	font-size: 22px;
	color: #1E1A1F;
}

.add-this-class thead tr {
	 background-color: #FAE2E2;
	color: #161616 !important;
}

.add-this-class thead th {
	color: #161616 !important;
	background-color: #FAE2E2;
	 
}
.add-this-class thead th .form-control{background-color: #fff;}

.add-this-class tbody td {
	background-color: #fff !important;
	color: #161616 !important;
	font-weight: 400;
}

.primary-btn {
	background: #DD3731 !important;
	border-radius: 100px !important;
	color: #fff !important;
	border: none !important;
	padding: 0px 16px 0px 16px !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	height: 40px !important;
	line-height: 40px;

}

.card-form-primary-btn {
	width: 80px;
	height: 35px;
	background: #DD3731;
	border-radius: 100px;
	color: #fff;
	border: none !important;
	padding: 10px 16px 10px 16px !important;
	font-size: 14px !important;
	font-weight: 500 !important;
}

.card-form-outline-btn {
	width: 80px;
	height: 35px;
	background: #fff !important;
	color: #DD3731 !important;
	border-radius: 100px;
	border: #7D747E solid .5px;
	padding: 0px 16px 0px 16px !important;
	line-height: 40px;
	font-size: 14px !important;
	font-weight: 500 !important;
}

.secondary-btn {
	background: rgba(221, 55, 49, 0.15) !important;
	color: #1E1A1F;
	font-weight: 600;
	 
	border-radius: 100px;
	box-shadow: none;
	padding: 0px 16px 0px 16px !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	height: 40px !important;
	line-height: 40px;
	 
}

.outline-btn {
	background: #fff !important;
	color: #DD3731 !important;
	border-radius: 100px;
	border: #7D747E solid .5px;
	padding: 0px 16px 0px 16px !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	height: 40px !important;
	line-height: 40px;

}

.primary-btn:hover,
.secondary-btn:hover {
	background: #DD3731 !important;
	color: #fff !important;
	border: #DD3731 solid .5px;
}

.custom-table-body tr {
	background-color: white !important;
}

.custom-table-body tr:nth-of-type(odd) {
	background-color: white !important;
}

.custom-table-body tr:nth-of-type(even) {
	background-color: white !important;
}

.card-container { padding: 0;
}

.body {
    margin:64px 0 16px 16px;
    background: #fff;
    height: calc(100vh - 82px);
    overflow: auto;
    border-radius: 16px;
    width: calc(100% - 30px) !important;
    padding: 16px; }
.body .card{ margin-bottom: 0; }

 


.card-form {
	background: #fff !important;
	border-radius: 24px;
	padding: 16px !important;
}

.custom-dropdown-item:active {
	background-color: #DD3731 !important;
	color: #fff !important;
}


.custom-dropdown-item{border-radius: 16px;}
.custom-dropdown-item:focus,
.custom-dropdown-item:hover {
	background-color: rgba(221, 55, 49, 0.1);
	cursor: pointer;  color:#2D0B3D

}
.dropdown-menu{padding: 4px;}
.dropdown-menu .icon{margin-right: 6px;}

.app-font,
.form-multi-select-options,
.form-text {
	color: #1E1A1F !important;
}

.form-multi-select-option {
	word-wrap: break-word;
}

.form-control,
.form-multi-select {
	border: none;
	border-bottom: 1px solid #4C444D;
	border-radius: 0;
	background-color: #f4f4f4;
	color: #1E1A1F;
	padding: 8px;
}

.icon-custom-size {
	
}

.dashboard-card {
	 
	border-radius: 24px;
	box-shadow: none;
	padding: 0px;
}

.dashboard-card-title {
	color: #1E1A1F !important;
	font-weight: 400;
	font-size: 57px;
	line-height: 63px;
	text-align: right;
}

.dashboard-card-sub-title {
	color: #1E1A1F !important;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: .15px;
	text-align: right;
}

.content-card {
	border-radius: 16px !important;
	box-shadow: none !important;
}

.href-link {
	text-decoration: none;
}

.flex-all-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-align {
	display: flex;
	align-items: center;
}

.outline-dashboard-link {
	background: #fff !important;
	color: #DD3731 !important;
	border-radius: 100px;
	border: #7D747E solid .5px;
	padding: 5px 10px;
}

.icon-btn {
	width: 50px;
	background: transparent !important;
	border-radius: 100px;
	box-shadow: none !important;
	color: #1E1A1F !important;
}

.modal-footer {
	margin-top: 20px !important;
}

.w-140 {
	width: 140px;
}

.w-full {
	width: 100% !important;
}

.title-container {
	margin-left: 0px;
}

.nav-tabs {
	background: rgba(221, 55, 49, 0.1);
	cursor: pointer;
}

.nav-tabs .nav-item .active {
	border-radius: 0px !important;
	background: rgba(221, 55, 49, 0.1) !important;
	color: #DD3731 !important;
	border-bottom: 2px solid !important;
	max-width: 220px !important;
}

.nav-tabs .nav-item .nav-link {
	color: #1E1A1F;
	font-weight: 500;
}

.date-picker-body {
	color: #1E1A1F !important;
}

.calendar-header-cell-inner,
.calendar-nav-date .btn {
	color: #1E1A1F !important;
}

.selected .calendar-cell-inner {
	background: #DD3731 !important;
}

.tab-content .active {
	width: 100% !important;
}

.accordion-button:not(.collapsed) {
	background-color: transparent;
	color: #1E1A1F !important;
}

.accordion-button.collapsed {
	background-color: inherit;
	color: #1E1A1F !important;
}

[class="card app-card"] {
	margin: 0 !important;
}

.modal-dialog {
	width: 80% !important;
}

.top-nav-dropdown .form-multi-select {
	padding: 6px !important;
	height: 40px !important;
	border: none !important;
	border-radius: 28px !important;
}

.top-nav-dropdown .form-multi-select .form-multi-select-search {
	padding: 3px 60px 5px 14px;
	background-color: transparent;
}

.avatar-md {
	height: 40px !important;
	width: 40px !important;
}

.header-margin {
	background-color: rgb(253, 245, 245);
	height: 8px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
}

.form-bg-white {
	background: #fff !important
}

.header-nav .nav-link {
	padding-right: 8px !important;
}

.sidebar-nav-title {
	margin-left: 12px;
	font-size: 14px;
	font-weight: 600;
	border-top: 1px solid #CEC3CE;
	margin: 18px 16px 8px;
	padding: 17px 0 10px 0px;
	font-weight: 500;
	text-transform: uppercase;
}

.brand-logo {
	transition: all 0.3s ease;
}

.brand-logo:hover {
	content: url('../../public/assets/images/brand/OXDR.svg');
}
.header-brand{width: 220px; padding: 0 8px;}
html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end){margin-left:0px;}
.card {width: 100%; min-height: 100%;}
.btn:disabled, .btn.disabled, fieldset:disabled .btn{border-color: #989799 !important; color: #989799!important;}
.btn-transparent:disabled{border-color: transparent !important;}


.client-dashboard-card{border-radius: 16px;}

.wrapper > .body .card{border-radius: 16px;}


.outline-btn:hover{border-color: #DD3731;}
.card-body .card-body{padding: 16px; border-radius: 16px;}
// .card-body .card-body{padding: 0px; border-radius: 0px;}
// .card .card{background-color: transparent;}
.dropdown-item{color: #4C444D;}

.input-group .btn-info{ background-color: #DD3731; color:#fff; border-radius: 5px; margin: 0px;}


.modal-title{color:#1E1A1F ; font-size: 22px;     font-weight: 500;
    line-height: 10px;
    padding: 0;
    margin: 8px 0 8px;}
	.modal-title p{margin-bottom: 0 !important;}
.modal-body{background-color: #fff; border-radius: 16px;}
.modal-header,
.modal-footer{background-color:#f4f4f4; border-color: #c6c6c6;}
	.modal-footer{position: absolute;
		width: 100%;
		left: 0;
		margin-top: 10px !important; bottom: 0;}

.modal-header .btn-close{    opacity: 0.9 !important;
    padding-right: 30px !important;}

	.modal-body .card-body,
	.modal-body .card-form{padding: 0 !important;}


.form-multi-select-search,
.form-control:focus{color:#231728}
.form-multi-select-search:placeholder-shown{background-color: transparent;}
.form-select{ border-color: #7D747E;}

.table thead th,
.form-select,
.col-form-label,
.table tr td,
.table tr th{color: #231728 !important;}
.modal-body .card{background-color: transparent;}
.card-body{color: #4C444D;}

	.modal-body{padding-bottom: 74px;}

	.pagination .page-link{background-color: transparent; color:#231728; text-align: center;}
	.pagination .page-link.active, .pagination .active > .page-link{background-color: #DD3731; border-color: #DD3731; color:#fff;}
	
	.modal-body .card-header{ padding: 0 0 10px; font-weight: 500; font-size: 16px; margin-bottom: 10px;}

	.badge-template{background-color:#FAE2E2; color:#231728; border: 1px solid #7D747E; padding: 6px 12px; font-size: 13px; border-radius: 16px;}
	.dropdown-header{font-size: 14px; background-color: transparent; color:#231728 ;}

	html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end){margin-left: -300px;}
.header-toggler{ margin: 0 0 0 15px;
	border: 0px solid #DD3731;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    text-align: center;
        padding: 0; color: #231728;}

		.header-toggler:hover{background-color: #ffe8e8;}		
html:not([dir=rtl]) .ps-1{padding-left: 0 !important;}
.sidebar{height:calc(100vh - 64px);}

*::-webkit-scrollbar
{
    width: 0.5em;
    z-index: 88;
    position: relative;
}

*::-webkit-scrollbar-track{
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb
 {
    background-color: #b5b8bb;
    outline: 1px solid #b5b8bb;
}



@media only screen and (max-width: 1200px) {
	svg.app_icon{width: 36px; height: 36px;}
	.title-container{margin-left: 0;}
	.dashboard-card-title{font-size: 36px; line-height: 40px;}
	.outline-dashboard-link{font-size: 14px;}
}

@media only screen and (max-width: 1024px) {

	.card-container {
		width: 100%;
		margin-left: 0;
	}
	
	

	
	.dashboard-card {
		width: 190px;
		height: 200px;
	}

	 

	.title-container {
		margin: 0;
	}

	 

	.modal-body .app-card {
		margin-left: 0 !important;
		margin-top: 0 !important;
	}
	
	// html:not([dir=rtl]) .sidebar:not(.sidebar-end){margin-left:-300px !important;}
	
	
}

.spinner-border{ top: calc(50vh - 20px) !important; left: calc(50vw - 20px) !important; position: fixed;}

.btn-primary{width: auto !important;}